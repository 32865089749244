body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	margin: 0;
	line-height: 1.5;
	font-family: Lato, Helvetica;
	font-size: 14px;
	width: 100%;
	height: 100%;
}

#root {
	height: 100%;
}

svg {
	width: 20px;
	height: 20px;
}

.react-datepicker-popper {
	/* modal ma z-index: 999 -> aby datepickery w modalach poprawnie dzialaly */
	z-index: 1000 !important;
}
